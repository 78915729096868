export const COLORS = {
  LIGHT_BLUE: "#64ACFF",
  BAR_LIGHT_BLUE: "#CAE2FF",
  BLUE: "#52A2FF",
  BLUE_TEXT: "#1967C3",
  BLUE_HOVER: "#1967C3",
  BLUE_08: "rgba(82, 162, 255, 0.8)",
  BLUE_07: "rgba(82, 162, 255, 0.7)",
  BLUE_06: "rgba(82, 162, 255, 0.6)",
  BLUE_05: "rgba(82, 162, 255, 0.5)",
  BLUE_04: "rgba(82, 162, 255, 0.4)",
  BLUE_02: "rgba(82, 162, 255, 0.2)",
  BLUE_01: "rgba(82, 162, 255, 0.1)",
  RED: "#FF3A2D",
  RED_TEXT: "#BA2B1F",
  RED_LIGHT: "#ECA9A5",
  RED_08: "rgba(253, 43, 30, 0.8)",
  RED_06: "rgba(253, 43, 30, 0.6)",
  RED_01: "rgba(253, 43, 30, 0.1)",
  RED_DARK: "#EB4739",
  YELLOW: "#F7C18F",
  YELLOW_TEXT: "#A36124",
  YELLOW_LIGHT: "#F8DABE",
  YELLOW_08: "rgba(247, 193, 143, 0.8)",
  YELLOW_06: "rgba(247, 193, 143, 0.6)",
  YELLOW_01: "rgba(247, 193, 143, 0.1)",
  GRAY: "#79869F",
  GRAY_08: "rgba(121, 134, 159, 0.8)",
  GRAY_06: "rgba(121, 134, 159, 0.6)",
  GRAY_04: "rgba(121, 134, 159, 0.4)",
  GRAY_01: "rgba(121, 134, 159, 0.1)",
  DARK: "#0C2D5E",
  WHITE: "#fff",
};

export const ASSESMENTS_COLORS = [
  "#1B71D6",
  "#178BFF",
  "#89C0FF",
  "#9CC9FB",
  "#CEE5FF",
  "#79CFFF",
  "#5DC2FB",
  "#30ABF0",
  "109AE8",
  "2DACD4",
  "#14CAE3",
  "43E8FF",
  "#5AE9E0",
  "#6CDFD8",
  "#9BDEDA",
  "#BFEEEB",
];
